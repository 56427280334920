"use client";

import {
  JoinUsForm,
  submitJoinUsForm,
} from "@/app/data/account/join-us.action";
import { useTranslation } from "@/app/i18n/client";
import Checkbox from "@/components/ui/Form/Checkbox";
import TextInputField from "@/components/ui/Form/TextInput";
import { CGCPrismicContext } from "@/prismicio";
import { Button } from "@cgc/ui/button";
import { Heading, Paragraph } from "@cgc/ui/typography";
import { Content } from "@prismicio/client";
import { SliceComponentProps } from "@prismicio/react";
import { Form, Formik, FormikHelpers } from "formik";
import Image from "next/image";
import { useRouter, useSearchParams } from "next/navigation";
import { ChangeEvent, useEffect, useState } from "react";
import * as Yup from "yup";

/**
 * Props for `HeroEmailInput`.
 */
export type HeroEmailInputProps = SliceComponentProps<
  Content.HeroEmailInputSlice,
  CGCPrismicContext
>;

/**
 * Component for "HeroEmailInput" Slices.
 */
const HeroEmailInput = ({
  slice,
  context,
}: HeroEmailInputProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [url, setUrl] = useState(null);
  const searchParams = useSearchParams();
  const product = searchParams.get("product");
  const discountCode = searchParams.get("discount_code");
  const router = useRouter();
  const { t } = useTranslation(context.lang, "slices");

  const validationSchema = Yup.object<JoinUsForm>({
    email: Yup.string()
      .trim()
      .required(t("join-us.fields.email.validation.required")),
  });

  useEffect(() => {
    if (url) {
      router.push(url);
    }
  }, [url]);

  const handleFormSubmit = (
    values: JoinUsForm,
    formikHelpers: FormikHelpers<JoinUsForm>,
  ) => {
    try {
      const response = submitJoinUsForm({
        email: values.email,
        product,
        discountCode,
      });
      setIsLoading(true);

      formikHelpers.setSubmitting(false);
      setUrl(response);
    } catch (error) {}
  };

  const [accepted, setAccepted] = useState(false);
  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setAccepted(checked);
  };

  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      className="text-cgc-blue relative h-fit"
    >
      <Image
        src={slice.primary.product_image.url}
        alt={slice.primary.product_image.url || "image"}
        width={1500}
        height={900}
        className="hidden max-h-[900px] w-full object-cover md:block"
      />

      <div
        className={`relative inline-block h-fit w-full py-0 align-middle md:absolute md:left-0 md:top-0 md:grid md:h-full md:grid-cols-2 md:justify-items-end`}
      >
        <div className="relative h-full w-full">
          <div className="flex h-full w-full flex-wrap items-center justify-center md:justify-start">
            <Image
              src={slice.primary.product_image.url}
              alt={slice.primary.product_image.url}
              width={500}
              height={500}
              className="h-80 w-full md:hidden"
            />

            <div className="absolute bottom-0 mx-7 h-fit w-fit rounded-t-lg bg-white bg-opacity-80 px-5 py-2 md:relative md:mx-0 md:w-96 md:rounded-r-lg md:rounded-tl-none">
              <Heading className="md:text-xxl w-full text-center text-5xl md:text-left">
                <span>£{slice.primary.product_price}! </span>
                {slice.primary.product_full_price && (
                  <span className="text-gray-500 line-through opacity-80">
                    £{slice.primary.product_full_price}
                  </span>
                )}
              </Heading>
              <Heading size="h4" className="max-w-96 pt-4">
                {slice.primary.product_description}
              </Heading>
            </div>
          </div>
        </div>

        <div className="flex h-full items-center md:px-10 md:py-10">
          <div id="email-input" className="w-full max-w-[504px]">
            <div className="md:bg-cgc-grey-10 rounded-lg p-3 text-center md:p-10 md:text-left">
              <Heading size="h1" className="mb-4">
                {slice.primary.promotion_heading}
              </Heading>
              <Heading size="h2" className="mb-4">
                {slice.primary.promotion_sub_heading}
              </Heading>

              <div className="pb-4 text-left">
                {slice.primary.reasons_to_buy.map((reason) => {
                  return (
                    <Paragraph size="p1" className="mb-2">
                      ✔ {reason.copy}
                    </Paragraph>
                  );
                })}
              </div>

              <Heading size="h2" className="mb-4">
                Let's get you started
              </Heading>
              <Formik<JoinUsForm>
                initialValues={{
                  email: "",
                  marketing: accepted,
                }}
                validationSchema={validationSchema}
                onSubmit={handleFormSubmit}
              >
                {({ isSubmitting }) => (
                  <Form className="w-full px-10 md:px-0">
                    <TextInputField
                      className="w-72 min-w-64 md:w-96"
                      name="email"
                      placeholder="Enter your email address here"
                    />
                    <div className="flex flex-col">
                      <Button
                        color="primary"
                        className="my-2 flex w-full justify-center px-10 md:w-96"
                        type="submit"
                        isLoading={isLoading}
                        disabled={isSubmitting}
                      >
                        Add and continue
                      </Button>

                      {slice.primary.marketing_disclaimer && (
                        <div className="flex w-full flex-col">
                          <Checkbox
                            name="marketing"
                            label={slice.primary.marketing_disclaimer}
                            className="pt-4"
                            handleCheckboxChange={handleCheckboxChange}
                          />
                        </div>
                      )}
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroEmailInput;

"use client";

import { OfferAwareText } from "@/app/[lang]/(landing)/offer-utils/OfferAwareText";
import { useOffer } from "@/app/[lang]/(landing)/offer-utils/OfferContext";
import { InlineReviews } from "@/components/ui/Reviews/InlineReviews";
import { sliceAnchor } from "@/utils/prismic";
import { Paragraph } from "@cgc/ui/typography";
import { clsx } from "@cgc/ui/utils/clsx";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import { Content } from "@prismicio/client";
import { SliceComponentProps } from "@prismicio/react";
import { useSearchParams } from "next/navigation";
import { useEffect, useState } from "react";

/**
 * Props for `LandingPageTopBanner`.
 */
export type LandingPageTopBannerProps =
  SliceComponentProps<Content.LandingPageTopBannerSlice>;

/**
 * Component for "LandingPageTopBanner" Slices.
 */
const LandingPageTopBanner = ({
  slice,
}: LandingPageTopBannerProps): JSX.Element => {
  const { discountForUrl } = useOffer();
  const [composedUrl, setComposedUrl] = useState<string>(slice.primary.link);
  const currentPageSearchParams = useSearchParams();

  useEffect(() => {
    try {
      if (slice.primary.link.startsWith("#")) {
        // If the URL is a hash, do not modify it
        return;
      }

      if (
        !(
          slice.primary.link.startsWith("https://") ||
          slice.primary.link.startsWith("http://")
        )
      ) {
        // If the URL is not an absolute URL, add the current origin
        slice.primary.link = new URL(
          slice.primary.link,
          window.location.origin,
        ).toString();
      }

      const baseUrl = new URL(slice.primary.link);
      const queryParams = new URLSearchParams(baseUrl.search);

      // Append the current URLs query params
      for (const [key, value] of currentPageSearchParams.entries()) {
        queryParams.set(key, value);
      }
      const applicableDiscount = discountForUrl(slice.primary.link);
      queryParams.set("discount_code", applicableDiscount.discount);
      baseUrl.search = queryParams.toString();
      setComposedUrl(baseUrl.toString());
    } catch (error) {}
  }, [slice.primary.link]);

  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      id={sliceAnchor(slice)}
      className={clsx({
        "bg-cgc-blue": slice.primary.color === "blue",
        "bg-cgc-green-40": slice.primary.color === "green",
        "bg-cgc-yellow": slice.primary.color === "yellow",
      })}
    >
      {slice.variation === "withReviews" && (
        <div
          className={clsx(
            "mx-auto flex w-full max-w-screen-xl items-center justify-center gap-1 px-3 py-3 text-center no-underline lg:gap-2 lg:py-2",
            {
              "text-white": slice.primary.color === "blue",
              "text-cgc-blue":
                slice.primary.color === "green" ||
                slice.primary.color === "yellow",
              "flex-col gap-4 lg:flex-row": slice.variation === "withReviews",
            },
          )}
        >
          {slice.primary.text && (
            <>
              <Paragraph className="font-semibold">
                <OfferAwareText text={slice.primary.text} />
              </Paragraph>
            </>
          )}
          {slice.variation === "withReviews" && <InlineReviews />}
        </div>
      )}

      {slice.variation === "default" && (
        <a
          href={composedUrl || "#"}
          className={clsx(
            "mx-auto flex w-full max-w-screen-xl items-center justify-center gap-1 px-3 py-3 text-center no-underline lg:gap-2 lg:py-2",
            {
              "text-white": slice.primary.color === "blue",
              "text-cgc-blue":
                slice.primary.color === "green" ||
                slice.primary.color === "yellow",
            },
          )}
        >
          {slice.primary.text && (
            <>
              <Paragraph className="font-semibold">
                <OfferAwareText text={slice.primary.text} />
              </Paragraph>
              {slice.variation === "default" && (
                <ArrowRightIcon className="inline h-4 w-4" />
              )}
            </>
          )}
        </a>
      )}
    </section>
  );
};

export default LandingPageTopBanner;
